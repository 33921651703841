import React from 'react'
import './real.css'
import YouTube from '@u-wave/react-youtube';
import { Embed } from 'semantic-ui-react'

class Real extends React.Component {
    render() {
        const size = document.body.clientWidth
        return(
                <div>
                    {size > 1875 ? (
                        <div className="divReal">
                            <YouTube video="BNK7u3DOrac" width="420" height="250" className="player"/>
                            <YouTube video="PGiB-S_7P-s" width="420" height="250" className="player"/>
                            <YouTube video="Xob79gGZcyA" width="420" height="250" className="player"/>
                            <YouTube video="QdTnvQNgyRU" width="420" height="250" className="player"/>
                            <YouTube video="txQzleXJgMc" width="420" height="250" className="player"/>
                            <YouTube video="lwwfZOip060" width="420" height="250" className="player"/>
                            <YouTube video="j1muEMUuO6I" width="420" height="250" className="player"/>
                            <YouTube video="znKZNP1zCvU" width="420" height="250" className="player"/>
                        </div>
                        ) : false}
                        {size <= 1025 ? (
                            <div className="divRealmobile">
                                <YouTube video="BNK7u3DOrac" width="300" height="220" className="player"/>
                                <YouTube video="PGiB-S_7P-s" width="300" height="220" className="player"/>
                                <YouTube video="Xob79gGZcyA" width="300" height="220" className="player"/>
                                <YouTube video="QdTnvQNgyRU" width="300" height="220" className="player"/>
                                <YouTube video="txQzleXJgMc" width="300" height="220" className="player"/>
                                <YouTube video="lwwfZOip060" width="300" height="220" className="player"/>
                                <YouTube video="j1muEMUuO6I" width="300" height="220" className="player"/>
                                <YouTube video="znKZNP1zCvU" width="300" height="220" className="player"/>
                            </div>
                        ) : false }
                        {size < 1875 && size >= 1636 ?
                        (
                            <div className="divReal">
                                <YouTube video="BNK7u3DOrac" width="350" height="200" className="player"/>
                                <YouTube video="PGiB-S_7P-s" width="350" height="200" className="player"/>
                                <YouTube video="Xob79gGZcyA" width="350" height="200" className="player"/>
                                <YouTube video="QdTnvQNgyRU" width="350" height="200" className="player"/>
                                <YouTube video="txQzleXJgMc" width="350" height="200" className="player"/>
                                <YouTube video="lwwfZOip060" width="350" height="200" className="player"/>
                                <YouTube video="j1muEMUuO6I" width="350" height="200" className="player"/>
                                <YouTube video="znKZNP1zCvU" width="350" height="200" className="player"/>
                            </div>
                         ) : false}
                         { size < 1636 && size > 1025 ? (
                           <div className="divReal">
                                <YouTube video="BNK7u3DOrac" width="270" height="210" className="player"/>
                                <YouTube video="PGiB-S_7P-s" width="270" height="210" className="player"/>
                                <YouTube video="Xob79gGZcyA" width="270" height="210" className="player"/>
                                <YouTube video="QdTnvQNgyRU" width="270" height="210" className="player"/>
                                <YouTube video="txQzleXJgMc" width="270" height="210" className="player"/>
                                <YouTube video="lwwfZOip060" width="270" height="210" className="player"/>
                                <YouTube video="j1muEMUuO6I" width="270" height="210" className="player"/>
                                <YouTube video="znKZNP1zCvU" width="270" height="210" className="player"/>
                            </div>
                        ): false }
                </div>
                )
            }
    }

export default Real