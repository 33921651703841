import React from 'react'
import logo from '../../ressources/LogoWissiz.png'
import './header.css'
import { Icon, Menu } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

class Header extends React.Component {

    constructor(props) {
        super(props)
        this.state = { activeTab: "1" }
    }
    openLink(url) {
        window.open(url, "_blank");
    }

    render() {
        return (
            <div className="Header">
                <img src={logo} alt="" className="logo" onClick={() => this.props.history.push('/')}></img>
                <div className="iconWrapper">
                    <Icon link className="network" id="1" name="instagram" size="big" onClick={() => this.openLink('https://www.instagram.com/wissiz/')}/>
                    <Icon link className="network" id="3" name="youtube" size="big" onClick={() => this.openLink('https://www.youtube.com/channel/UCBdPpMiCdn6BnTWG-b3GW2g')}/>
                    <Icon link className="network" id="4" name="facebook" size="big" onClick={() => this.openLink('https://www.facebook.com/wissiz')}/>
                </div>
                <div className="navBar">
                    <Menu className="menuNavBar" borderless fluid widths={3}>
                        <Menu.Item className="test" name='société' fitted onClick={() => this.props.history.push('/')}>REALISATIONS</Menu.Item>
                        <Menu.Item className="test" name='société' fitted onClick={() => this.props.history.push('/contact')}>CONTACT</Menu.Item>
                        <Menu.Item className="test" name='société' fitted onClick={() => this.props.history.push('/makingof')}>A PROPOS</Menu.Item>
                    </Menu>
                </div>
            </div>
        )
    }
}

export default withRouter(Header)