import React from 'react'
import './contact.css'
import { Input, TextArea, Form, Button, Message } from 'semantic-ui-react'
import { template } from '@babel/core';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isSend: false}

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleInputChange(event) {
        const target = event.target
        const name = target.name
        this.setState({ [name]: target.value })
    }

    handleSubmit(event) {
        event.preventDefault()

        if (!this.state.firstName || !this.state.message || !this.state.mail) {
            this.setState({isSend: 'error'})
            return
        }
        var template_params = {
            "from_name": this.state.firstName,
            "message": this.state.message,
            "from_mail": this.state.mail
         }
         var service_id = "default_service";
         var template_id = "wissizweb";
         window.emailjs.send(
            service_id,
            template_id,
            template_params
        ).then(res => this.setState({isSend: true})).catch(err => this.setState({isSend: 'error'}))
    }

    render() {
        return(
            <div className="divContact">
                <p>C O N T A C T</p>
                <p>Vous désirez nous contacter pour un projet ?</p>
                <p>Nous vous répondrons dans un délai de 48h.</p>
                <p>VEUILLEZ REMPLIR LE FORMULAIRE CI-DESSOUS</p>
                <br></br>
                {this.state.isSend === 'error' ? (
                    <Message negative>
                        <Message.Header>Une erreur a été détécté</Message.Header>
                        <p>
                            Une erreur a été détécté dans votre formulaire, veuillez vérifier que tous les champs soient remplis, si l'erreur persiste veuillez réessayer ultérieurement
                        </p>
                    </Message>
                ) : false }
                {this.state.isSend === true ? (
                    <Message positive>
                      <Message.Header>Votre message a bien été envoyé !</Message.Header>
                      <p>
                        Nous vous repondrons dans les plus brefs délais
                      </p>
                    </Message>
                ) : (
                <div className="formWrapper">
                    <Form>
                        <Input placeholder="Nom Prénom" name="firstName"className="formName firstInput" onChange={this.handleInputChange}/>
                        <Input fluid placeholder="adresse mail" name="mail" className="mailInput" onChange={this.handleInputChange}/>
                        <Input fluid placeholder="Sujet" name="subject" className="mailInput" onChange={this.handleInputChange}/>
                        <TextArea placeholder="Votre message" name="message" onChange={this.handleInputChange}/>
                        <Button content="envoyer" basic className="submitButton" onClick={this.handleSubmit}/>
                    </Form>
                </div>)}
            </div>
        )
    }
}

export default Contact