import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import Header from './components/header/header.js'
import Real from './components/realisations/real.js'
import MakingOf from './components/makingof/makingof.js'
import Contact from './components/contact/contact.js'

function App() {
  return (
    <Router>
      <div className="App">
        <header>
          <Header/>
        </header>
        <div>
          <Route exact path="/" component={Real}></Route>
          <Route exact path="/makingof" component={MakingOf}></Route>
          <Route exact path="/contact" component={Contact}></Route>
        </div>
      </div>
    </Router>
  );
}

export default App;
