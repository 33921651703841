import React from 'react'
import { Image } from 'semantic-ui-react'
import './makingof.css'
import image1 from './pictures/wissizprod.fr/4.jpg'
import image2 from './pictures/wissizprod.fr/8.jpg'
import image3 from './pictures/wissizprod.fr/5.jpg'
import image4 from './pictures/wissizprod.fr/3.jpg'
import image5 from './pictures/wissizprod.fr/6.jpg'
import image6 from './pictures/wissizprod.fr/7.jpg'
import image7 from './pictures/top1.jpg'
import image8 from './pictures/top2.jpg'


class MakingOf extends React.Component {
    render() {
        return(
            <div className="divReal">
                <div className="text">
                    <p>
                        Depuis 2016, Wissiz productions a réalisé plus de 300 clips en France et à l'étranger. Wissiz est une production audiovisuelle basée en région parisienne.
                    </p>
                    <p>
                        Nous réalisons principalement des clips musicaux, campagne publicitaires pour entreprises et particuliers et mariage.
                        Nos projets sont réalisés avec du matériel professionnel et une équipe passionnée.
                    </p>
                </div>
                <div className="imageWrapper">
                    <Image src={image7} centered size='big'/>
                    <Image src={image8} centered size='big'/>
                    <Image src={image1} centered size='big'/>
                    <Image src={image2} centered size='big'/>
                    <Image src={image3} centered size='big'/>
                    <Image src={image4} centered size='big'/>
                    <Image src={image5} centered size='big'/>
                    <Image src={image6} centered size='big'/>
                </div>
            </div>
        )
    }
}

export default MakingOf